// app config.js Definitions and functions used for all sections of the application
/* globals Storage, localforage, config, console, alert */
/**
 * @callback configLoadedCallback
 * @param {dramscanConfig} config - The configuration loaded into memory.
 */

/**
 * @typedef {Object} dramscanConfig
 * @property {String} installationPath - The path to the Windows CE installation folder on handheld devices
 * @property {String} applicationVersion - The version of the application
 * @property {string} environmentName - The environment of the application
 * @property {string} apiUrl - The URL of the API. Leave blank for allowing IIS to redirect.
 * @property {Boolean} allowNullServerTime
 */

var dramscanConfig = {
  applicationVersion: "0",
  installationPath: "\\Program Files\\Dramscan",
  environmentName: "Unknown",
  apiUrl: "",
  allowNullServerTime: false
};
var configLoading = false;
var configIsReady = false;

/**
 * @description Get application config. If it has not been loaded to memory, then it will load from the JSON file.
 * @param {configLoadedCallback} callback
 */
function getConfig(callback) {
  "use strict";
  if (configIsReady) {
    callback(dramscanConfig);
  } else if (configLoading) {
    // If the config is loading then we set a timeout to recheck shortly.
    setTimeout(function() {
      getConfig(callback);
    }, 10);
  } else {
    configLoading = true;
    createConfig(callback);
  }
}

function createConfig(callback) {
  "use strict";
  // default config - create from the initial load
  var defaultConfig = dramscanConfig;

  // config object to be returned, this will be mutated.
  // dramscan.config will be copied into this var, if that key doesn't exist in dramscan.config the default value will be used
  /**
   * @type {dramscanConfig}
   */
  var newConfig = defaultConfig; // Initialise new object with the defaults.

  $.ajax({
    url : "dramscan.config.json",
    dataType: "text",
    success : function (data) {
      $.ajax({
        url : "version.txt",
        dataType: "text",
        success : function (versionFile) {
          if (versionFile) {
            newConfig.applicationVersion = versionFile.split("\n")[0];
          }

          if (!data) {
            // No data came from the config.json file this is bad.
            dramscanConfig = newConfig; // set what we can.
            configLoading = false; // Reset this so we can try again later.
            return;
          }
          var parsedConfig = JSON.parse(data);

          newConfig.environmentName = parsedConfig.environmentName || defaultConfig.environmentName;
          newConfig.installationPath = parsedConfig.installationPath || defaultConfig.installationPath;
          newConfig.allowNullServerTime = parsedConfig.allowNullServerTime || defaultConfig.allowNullServerTime;
          newConfig.apiUrl = parsedConfig.apiUrl;

          dramscanConfig = newConfig;
          configIsReady = true;
          configLoading = false;

          callback(newConfig);
        }
      });
    }
  });
}

/**
 * @description Works out the request path to the API - Warning - If dramscanConfig hasn't loaded it will fail.
 * @param {String} resource
 * @returns {string}
 */
function getRequestPath(resource) {
  "use strict";
  // returns the apiUrl then the path like Drams Web. If apiUrl is blank then it will be relative to the site anyway.
  return dramscanConfig.apiUrl + '/DramscanService/rest/dataservices/' + resource;
}

localforage.config({
  name: 'transactions',
  driver: localforage.LOCALSTORAGE
});

Storage.prototype.testItem = function(key) {
	"use strict";
	try {
		return (localStorage.getItem(key));
	} catch (e) {
    console.log("Failed to read localStorage to test " + key);
		return false;
	}
};

Storage.prototype.setObject = function (key, value) {
  try {
    if(typeof value === "undefined") {
      console.log("Object for ",key," undefined.");
      return;
    }
    this.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log("Failed to save object '"+key+"' to localStorage: " + e);
		alert("ALL YOUR DATA IS BELONG TO US");
  }
};

Storage.prototype.getObject = function (key) {
  if(this.getItem(key)) {
    try {
      return JSON.parse(this.getItem(key));
    }
    catch (e) {
      console.log("Get Object caught errors:");
      console.log(e);
      // The key is corrupt so remove it. TOOD is this right? what if we cannot read due to something else
      this.removeItem(key);
      return [];
    }
  } else {
		//Todo should this return null? implications?
    return []; // returns empty array if key doesn't exist. should create object, and add a getArray equivalent. todo
  }
};

Storage.prototype.setVariable = function (key, value, canBeBlank) {
  if(value !== "" || canBeBlank) {
		try {
			this.setItem(key, value);
		}
		catch (e) {
			console.log("Failed to save variable '"+key+"' to localStorage: " + e);
			alert("ALL YOUR VARIABLES ARE BELONG TO US");
		}
  }
};

Storage.prototype.getVariable = function (key) {
  return (this.getItem(key))? this.getItem(key):"";
};

/**
 * @typedef {Object} WavPlayer
 * @property {function} Play
 */





